@media screen and (max-width: 1399px) {
  :root {
    --font_40: 2.5rem;
    --font_60: 2.75rem;
    --font_75: 3.3875rem;
    --font_100: 5.25rem;
    --font_32: 1.75rem;
  }
  .set_bgContent {
    margin: 30px auto 30px;
  }
  h3 {
    font-size: 36px;
  }
  .card_details {
    font-size: 11px;
  }
  .desk_list_subline h5 {
    font-size: 18px;
  }
  .logo_elements {
    gap: 24px;
  }
  .logo_elements {
    width: 40%;
  }
  .menu_layer_menu {
    width: 60%;
  }
}
@media screen and (max-width: 1199px) {
  .form_elements {
    flex-wrap: wrap;
  }
  .item_flexInner,
  .artical_List {
    flex: 0 0 calc(33.33% - 16px);
  }
  .waves_title {
    font-size: 38px;
    line-height: 64px;
  }
  :root {
    --font_40: 28.8px;
    --font_60: 32px;
    --font_75: 38.2px;
    --font_100: 68px;
    --font_32: 24px;
    --font_24: 22.4px;
  }
  .sec_contactUs {
    padding: 40px 0;
  }
  .contact_subTitle,
  .waves_title {
    font-size: 30px;
  }
  .form_calllist ul a {
    font-size: 16px;
  }
  .header_elements .menu_items ul {
    gap: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .menu_layer .search_items{
    display: none !important;
  }
  .row.set_howWork {
    flex-direction: column;
    gap: 20px;
}
.row.set_howWork .col-md-6 {
  flex: 0 0 auto;
  width: 100%;
}
.image_surf img {
  max-width: 100%;
}
  .menu_layer_menu {
    grid-gap: 15px;
    gap: 15px;

    .header_elements .menu_layer {
      gap: 15px;
    }
    .list_dropMenu,
    .header_elements .menu_items ul li a,
    .cart_elements a {
      font-size: 14px;
    }
  }
  .logo_text img {
    min-width: 130px;
  }
  .logo_elements {
    grid-gap: 15px;
    gap: 15px;
  }
}
@media screen and (max-width: 991px) {
  .instaLogo{
    display: block !important;
  }
  .header_elements .menu_layer {
    align-items: center !important;
    flex-direction: row !important;
  }
  .form_elements {
    flex-wrap: wrap;
    justify-content: center;
  }
  .item_flexInner,
  .artical_List {
    flex: 0 0 calc(50% - 24px);
  }
  .bg_homeImg {
    height: 100%;
  }
  .bg_homeImg img {
    -o-object-fit: cover;
    object-fit: cover;
  }
  .menu_items {
    position: absolute;
    top: 95px;
    background: #fff;
    padding: 10px;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .logo_main
  {
    margin-bottom: 0;
  }
  .list_dropMenu{
    width: 100%;
    justify-content: space-between;
  }
  .header_elements .menu_items ul {
    width: 100%;
    flex-direction: column;
  }
  .btn_down {
    position: absolute;
    right: 0;
    top: 0;
  }
  .dropdown_elements {
    align-items: self-start;
    flex-direction: column;
  }
  .dropdown_items_inner {
    position: relative;
    width: 100%;
    transform: translateY(0px);
    z-index: 1;
    width: 100%;
    right: 0;
    transition: all 0.3s;
  }
  .dropdown_items {
    width: 100%;
  }
  .logo_elements {
    gap: 20px;
    width: 90%;
  }
  .item_flexInner_card {
    flex: 0 0 calc(50% - 12px);
  }
  .deskDettails_card {
    height: 260px;
  }
  .logo_text img {
    min-width: 100px;
  }
  .menu_layer_menu {
    gap: 5px;
    width: 25%;
    margin: 0;
  }
  .mobile-nav {
    right: 15px;
    margin-top: 10px;
    top: 12px;
    cursor: pointer;
    z-index: 2;
    display: block;
    /* margin-left: 20px; */
  }
  .search_items {
    width: 138%;
    position: relative;
    margin-top: 5px;
  }
  .bar1,
  .bar2,
  .bar3 {
    background-color: var(--theme_black);
    cursor: pointer;
    height: 2px;
    margin: 7px 0px;
    transition: all 0.4s ease 0s;
    width: 30px;
  }
  .bar2 {
    width: 20px;
    left: -5px;
    position: relative;
  }
  .header_elements .header_bar {
    align-items: center;
  }
  .menu_items_release {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  .dropdown_elements .dropdown {
    width: 100% !important;
    border-bottom: 1px solid #cccccc4d;
    padding-bottom: 5px !important;
  }
  .dropdown_elements .dropdown-menu.show {
    display: block;
    width: 100%;
  }
  .header_elements .menu_items ul li a {
    font-size: 14px;
    width: 100%;
    display: block;
    border-bottom: 1px solid #cccccc4d;
    padding-bottom: 5px;
  }
  .header_elements .menu_items ul li:last-child a {
    border-bottom: none;
  }
}
@media screen and (max-width: 767px) {
  .image_surf img{
    object-position: 0px;
  }
  .break_word{
    display: block;
    word-break: break-all;
  }
  .logo_text img {
    max-width: 180px;
}
  .pb_50 {
    padding-bottom: 25px;
}
  .sub_details {
    margin-top: 20px;
    padding: 0 15px 15px;
}
  .item_flexInner_card {
    flex-basis: 100%;
  }
  .logText {
    display: none;
  }
  .header_elements .header_bar {
    align-items: flex-start;
  }
  .logo_elements {
    gap: 10px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .card_checkout {
    margin-top: 20px;
  }
  .search_section {
    padding: 30px 0;
  }
  .find_data h3 {
    line-height: 42px;
    margin-bottom: 16px;
  }
  .header_elements .menu_layer {
    align-items: baseline;
  }
  .titel_line p {
    font-size: 16px;
  }
  h3 {
    font-size: 28px;
  }
  .item_flexInner,
  .artical_List,
  .prof_from .form_fields_prof {
    flex: 100%;
  }
  .w_50Set {
    width: 100% !important;
  }
  .btn {
    padding: 10px 20px;
  }
  .set_fullValue {
    font-size: 18px;
  }
  .titel_slider {
    font-size: 20px;
  }
  .profile_uploadField,
  .avatar_elements {
    flex-direction: column;
  }
  .avatar_elements {
    gap: 10px;
  }
  .avatar-upload .avatar-preview {
    width: 200px;
    height: 200px;
  }
  .upload_imgList ul li {
    flex: 0 0 calc(50% - 10px);
  }
  h4 {
    font-size: 26px;
  }
  .footer_elements {
    padding: 10px;
  }
  .deskDettails img {
    max-width: 100%;
  }
  .set_bgContent {
    height: 400px;
    margin: 30px auto 30px;
  }
  :root {
    --font_60: 24px;
    --font_100: 50px;
    --font_32: 18px;
    --font_24: 18.4px;
  }
  .sub_title {
    line-height: 34px;
  }
  .center_coverTitle p {
    line-height: 46px;
  }
  .cover_sec {
    padding: 100px 0;
  }

  .pb_60 {
    padding-bottom: 30px;
  }
  .pb_60 {
    padding-top: 30px;
  }
  .image_galleryTiitle {
    font-size: 24px;
  }
  .mb_40 {
    margin-bottom: 20px;
  }
  .gallery_innerTitle {
    font-size: 16px;
  }
  .goBackbtn {
    margin-bottom: 10px;
  }
  .searchHome_btn {
    width: 100%;
  }
  div#myTable_wrapper > .dt-layout-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dt-search {
    max-width: 180px;
  }
  div.dt-container .dt-paging .dt-paging-button {
    padding: 0.3em 0.8em;
  }
  .image_slider {
    min-height: 440px;
}
.form_calllist ul {
 
  flex-direction: column;
}
.custom_support {
  text-align: center;
}
.waves_bgImg {
  width: 50%;
  opacity: 0.65;
}
.contact_subTitle, .waves_title {
  z-index: 1;
  position: relative;
}

.prof_title{
  width: 100% !important;
  margin-bottom: 10px;
}
.profile_card_payout{
  width: 100% !important;
  gap: 0 !important;
  flex-direction: column;
}
.alert_update a button.btn.btn-purple.btn-sm.text-white {
  height: 40px;
}
.center_password {
  padding: 30px 20px;
}
.waves_title {
  line-height: 40px;
}
.btn {
  padding: 7px 20px;
}
}
@media screen and (max-width: 575px) {
  .titel_line p {
    flex-wrap: wrap;
  }
  .search_items_inner input {
    font-size: 12px;
  }
  .footer_elements ul {
    gap: 10px;
    font-size: 14px;
  }
  .footer_elements {
    padding-top: 20px;
  }
  div#myTable_wrapper > .dt-layout-row {
    display: block;
  }
  .dt-search {
    max-width: 100%;
  }
  .paging_full_numbers {
    justify-content: center;
  }
  .center_login {
    padding: 40px 20px 0;
}
.set_center {
  min-height: auto;
  padding: 50px 10px;
}
}
@media screen and (max-width: 575px) {
  .search_items {
    width: 145%;
  }
}
