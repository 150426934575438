@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form_icon {
  top: 46% !important;
}

.form_fields {
  margin-bottom: 20px !important;
}

.form_fields_drops select,
.form_fields_drops input {
  border: 1px solid #e7e7e7;
  color: #5e5e5e;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  -webkit-appearance: none;
  appearance: none;
  padding: 8px;
}

.cret_details {
  a {
    color: #4668ff !important;
  }
}
.requiredInput {
  position: absolute;
  left: 5px;
  bottom: -20px;
  font-size: 12px;
  color: red;
}

.checkboxReq {
  border-color: red;
}

.google-map {
  width: 100%;
}

.search_section fieldset {
  margin-bottom: 0 !important;
}

.cart_icon {
  margin-right: 5px !important;
}

.form_fields_drops {
  position: relative !important;
}

.form_fields_drops .select {
  border: 1px solid #e7e7e7;
  color: #5e5e5e;
  border-radius: 8px;
  width: 100%;
  /* height: 48px; */
  -webkit-appearance: none;
          appearance: none;
  /* padding: 8px; */
}

.prof_from .form_fields_prof {
  position: relative !important;
}

.center_password {
  background-color: var(--theme_white);
  max-width: 550px;
  width: 100%;
  border-radius: 10px;
  border: 10px #f7f7f7 solid;
  padding: 69px 96px;
  z-index: 99999;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0px;
  background-color: rgb(145 145 145 / 84%) !important;
  z-index: 99999;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Ensure the overlay is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-select {
  position: relative;
}
.list_dropMenu {
  background: unset;
  border: unset;
  padding: unset !important;
  color: #110163;
  font-weight: 500;
  font-size: 16px;
}
.menu_layer_menu .list_dropMenu:hover,
.menu_layer_menu .list_dropMenu:focus-visible,
.menu_layer_menu
  .list_dropMenu:first-child:active
  .menu_layer_menu
  .list_dropMenu.show {
  background-color: #fff !important;
  color: #110163 !important;
}
.menu_layer_menu .btn.show,
.menu_layer_menu .btn:first-child:active {
  background-color: #fff !important;
  color: #110163 !important;
}

.onEditor {
  align-items: center;
  grid-gap: inherit;
  gap: inherit;
}

.prof_title {
  font-family: "fantasy";
}

.input-group {
  flex-wrap: nowrap;
}
.input-group-text {
  height: 44px;
}
/* .dt-layout-row{
    display: flex !important; 
    justify-content: space-between !important;
    align-items: center !important;
} */

table.dataTable th.dt-type-numeric {
  text-align: left !important;
}

table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
  text-align: left;
}
.btn-purple {
  background-color: #110163 !important;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.text_small {
  color: #ffaaaa;
  font-family: serif;
}

.item_flexInner .icon_plus {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
main {
  min-height: calc(100vh - 190px);
}
.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}
.css-1fdsijx-ValueContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 8px;
}
.css-13cymwt-control,
.css-1fdsijx-ValueContainer,
.css-1hb7zxy-IndicatorsContainer {
  background: #f1f1f1 !important;
  border: none !important;
}
.css-1dimb5e-singleValue {
  font-size: 14px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  border-radius: 10px !important;
}

.css-13cymwt-control {
  border-radius: 10px !important;
}
.searchHome_btn {
  height: 50px;
  grid-gap: 5px;
  gap: 5px;
}
.set_end {
  align-items: flex-end;
  z-index: 9;
  position: relative;
}
.no_record {
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
  background: #f1f1f1 !important;
}
.css-t3ipsp-control:hover {
  border: none !important;
  box-shadow: none !important;
  background: #f1f1f1 !important;
}
.dropupload {
  position: relative;
}
.dropupload input{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}
.counter_dropBox{
    font-weight: 500;
}
div.dt-container .dt-search input ,.table_userDetails div.dt-container select.dt-input{
    height: 40px;
}
.avatar-upload .avatar-edit input + label {

    display: flex !important;
    justify-content: center;
    align-items: center;
}

.img_desk_card {
 background: unset !important;
    position: absolute;
    z-index: 1;
    border: none !important;
    bottom: 0;
    right: 0;
}

.public_trash {
    grid-gap: 10px;
    gap: 10px;
}
.img_uploadItem {
    height: 160px;
    width: 100%;
    object-fit: cover;
}
.uploadInner{
    position: relative;
}
.btn_uploadData.d-flex {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1;
}
.contact_info{
  position: relative;
}
.sec_sliderGallery{
  padding-top: 30px;
}

.image_slider {

  max-height: 570px;
  height: 100%;
  object-fit: cover;
}
.payment_logo {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}
.pay_logo {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 6px);
}
.btn_payment {
  a {
      min-width: auto;
  }

}
.btn_border {
  background-color: #fff !important;
  color: #ffd119 !important;
  border: 1px solid #ffd119 !important;
}
.css-19bb58m {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
}
.css-19bb58m input {
  height: 45px;
}
.del_usertitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
  color: red;
}

.del_user .card_images_admin {
  opacity: .25;
}
.alert_update{
  grid-gap: 15px;
  gap: 15px;
}
.alert_update a button.btn.btn-purple.btn-sm.text-white {
  min-width: 170px;
  height: 50px;
}
.alert_custom{
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
}
.css-hlgwow {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.dropdown-menu {
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 15%);
  position: relative;
  border-radius: 8px;
  inset: inherit !important;
  transform: none !important;
}
.css-1jqq78o-placeholder {
  font-size: 14px;
}
.multiple{
  position: absolute;
  z-index: 9;
  bottom: 15px;
  right: 15px;
}
.small-text{
  font-size: 22px;
  color: #525252;
}
.instaLogo{
  display: none;
}
.instaLogo img{
  max-width: 30px !important;
}
/*================= MASTER STYLESHEET =================================

=======================================================================*/
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --font_poppins: "Poppins", sans-serif;
  --theme_black: #000000;
  --theme_white: #fff;
  --theme_ligth_white: #f1f1f1;
  --theme_sub_white: #e7e7e7;
  --theme_dark_yellow: #ffd119;
  --theme_dark_blue: #3d3d3d;
  --theme_blue: #110163;
  --font_12: 0.75rem;
  --font_14: 0.875rem;
  --font_16: 1rem;
  --font_18: 1.125rem;
  --font_20: 1.25rem;
  --font_32: 2rem;
  --font_22: 1.375rem;
  --font_24: 1.5rem;
  --font_25: 1.5625rem;
  --font_45: 2rem;
  --font_50: 3.125rem;
  --font_40: 2.5rem;
  --font_60: 3.75rem;
  --font_75: 3.3875rem;
  --font_100: 6.25rem;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font_poppins);
  transition: padding-top 0.5s ease;
  font-weight: 400;
}

button:focus {
  outline: none;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  border-color: #52a5ff;
  box-shadow: none;
}

button {
  outline: none;
  cursor: pointer;
  border: unset;
  background: unset;
  color: var(--theme_black);
}

h3 {
  font-size: 40px;
}

a {
  text-decoration: none;
  color: var(--theme_black);
  transition: all 0.2s ease;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme_ligth_white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme_black);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::selection {
  background: var(--theme_black);
  color: var(--theme_dark_yellow);
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: var(--theme_dark_blue);
  border-radius: 8px;
  width: 100%;
  height: 44px;
  border: 1px solid var(--theme_sub_white);
  padding: 0 15px;
  background: var(--theme_ligth_white);
  font-size: 14px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: var(--theme_dark_blue) !important;
  outline: none !important;
  box-shadow: none !important;
  background: var(--theme_ligth_white) !important;
  border: none !important;
}

textarea {
  width: 100%;
  height: 150px;
  padding-top: 15px;
}

button,
button:hover,
button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

h2,
h1,
h3,
h4,
p {
  margin-bottom: 0px;
}

h1 {
  font-size: var(--font_75);
}

h2 {
  font-size: var(--font_45);
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 26px;
}

h6 {
  font-size: var(--font_18);
}

.image_galleryTiitle {
  font-size: 36px;
  font-weight: 600;
}

.btn_default {
  background: var(--theme_dark_yellow);
  font-weight: 500;
  padding: 12px 40px;
}

.btn {
  position: relative;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  border-radius: 8px;
}
.btn:hover {
  background-color: var(--theme_dark_blue);
  color: var(--theme_white);
}
.btn span {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s;
}

.btn_move {
  background-color: var(--theme_extra_gray);
  min-height: 55px;
  padding: 5px 35px;
}

.btn_more {
  min-height: 45px;
  min-width: 91px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_20 {
  margin-top: 20px;
}

.sec_padding {
  padding: 100px 0;
}

.button_full {
  padding: 5px 40px;
  background-color: var(--btn_bg);
}

.button_more {
  min-width: 145px;
  min-height: 45px;
}

.darkBg {
  background-color: var(--theme_dark_black);
}

.mb_16 {
  margin-bottom: 16px;
}

.mt_50 {
  margin-top: 50px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_22 {
  margin-top: 22px;
}

.mb_25 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mb_22 {
  margin-bottom: 22px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mt_40 {
  margin-top: 40px;
}

.mb_40 {
  margin-bottom: 40px;
}

.pb_100 {
  padding-bottom: 100px;
}

.pb_50 {
  padding-bottom: 50px;
}

.mainInput input {
  border-radius: 100px;
  border: 1px solid var(--theme_inputBorder);
  padding: 10px 20px;
  width: 100%;
  font-size: var(--font_14);
}
.mainInput input::placeholder {
  color: var(--theme_black);
}
.mainInput select {
  border-radius: 100px;
  border: 1px solid var(--theme_inputBorder);
  padding: 10px 20px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--font_14);
}
.mainInput select::placeholder {
  color: var(--theme_black);
}

.arrow_down {
  position: relative;
}
.arrow_down::after {
  width: 10px;
  height: 6px;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.form-check-input:checked {
  background-color: var(--theme_dark_yellow);
  border-color: var(--theme_dark_yellow);
}

.form-check-input:focus {
  border-color: var(--theme_blue);
  box-shadow: none;
}

label {
  color: var(--theme_dark_blue);
  font-size: 14px;
  margin-bottom: 8px;
}

.btn-check:checked + .btn,
.btn:focus-visible,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: var(--theme_white);
  background-color: var(--theme_dark_yellow) !important;
}

.set_center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 10px;
}

.bg_center {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;
}
.bg_center::after {
  content: "";
  background: linear-gradient( 186deg, rgb(0 37 60 / 28%) 0%, rgb(0 26 44 / 45%) 100% );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.center_login {
  background-color: var(--theme_white);
  max-width: 550px;
  width: 100%;
  border-radius: 10px;
  border: 10px #f7f7f7 solid;
  padding: 69px 96px;
  position: relative;
}

.logoImg {
  text-align: center;
  margin-bottom: 65px;
}
.logoImg img {
  max-width: 230px;
  width: 100%;
}

.form_fields {
  position: relative;
}

.form_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}

.cret_details {
  color: #00253c;
}
.cret_details h4 {
  font-weight: 700;
  margin-bottom: 15px;
}
.cret_details p,
.cret_details a {
  font-weight: 400;
  font-size: 14px;
  color: var(--theme_dark_blue);
}
.cret_details .form-control {
  padding-left: 45px;
}

.reset_Field {
  margin-top: 20px;
}

.reset_Field {
  font-size: 14px;
}
.reset_Field label,
.reset_Field a {
  color: var(--theme_dark_blue);
}

.btn_signLogin {
  width: 100%;
  background-color: var(--theme_dark_yellow);
  font-weight: 600;
  border: none;
  color: #101010;
  border-radius: 8px;
  padding: 10px;
}

.check_terms {
  margin: 92px 0 20px;
  display: flex;
  position: relative;
}

.actionSignup p {
  font-size: 14px;
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--theme_ligth_white);
}

.form_go p {
  text-align: center;
}

.goBtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bdc4cd;
  border-radius: 50%;
}
.goBtn svg {
  fill: var(--theme_white);
  height: 24px;
}

.externalLogin_inner {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  justify-content: center;
  margin-top: 23px;
}

.check_terms .form-check {
  display: none;
}

.btnBackHome {
  position: absolute;
  top: 15px;
  left: 15px;
}

.backHome svg {
  height: 24px;
  width: 24px;
  stroke: #00253c;
  transition: all 0.3s;
}
.backHome:hover svg {
  stroke: var(--theme_dark_yellow);
  transition: all 0.3s;
}

.onclickEye {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
}

.header_elements {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background-color: var(--theme_white);
  position: relative;
}
.header_elements .menu_layer {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}
.header_elements .menu_items ul {
  grid-gap: 24px;
  gap: 24px;
  font-size: var(--font_16);
}
.header_elements .menu_items ul li a {
  position: relative;
  color: var(--theme_blue);
  font-weight: 500;
  font-size: 16px;
}
.header_elements .menu_items ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 0.05em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.header_elements .menu_items ul li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.header_elements .header_bar {
  width: 100%;
  padding: 21px 0;
  position: relative;
}

.logo_text {
  font-weight: 500;
  display: flex;
}
.logo_text img {
  /* min-width: 170px; */
  width: 100%;
  /* max-width: 220px; */
  max-width: 70%;
  margin: 0 auto;
}
.logo_main{
  margin-bottom: 15px;
}
.menu_items_block {
  display: block !important;
  height: auto !important;
}

.mobile-nav_release .bar2 {
  left: 5px;
  transition: all 0.4s ease 0s;
}
.mobile-nav_release .bar1,
.mobile-nav_release .bar3 {
  position: relative;
  transform: translateX(-10px);
  transition: all 0.4s ease 0s;
}

.info_lang a {
  color: #a7a7a7;
}
.info_lang .active {
  color: var(--theme_black);
}

.form_search {
  background-color: #f6f6f6;
  border-radius: 40px;
}
.form_search input {
  border: none;
  background: unset;
}

.set_button_search {
  padding: unset;
  border: unset;
  background: unset;
}

.containerHeader {
  max-width: 1650px;
}

.cart_icon {
  margin-right: 10px;
}

.menu_layer_menu {
  grid-gap: 40px;
  gap: 40px;
  width: 50%;
  justify-content: center;
  margin-top: 25px;
}

.logo_elements {
  align-items: center;
  grid-gap: 80px;
  gap: 80px;
  width: 50%;
}

.search_items {
  max-width: 600px;
  width: 100%;
}

.dropdown_elements {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  position: relative;
}

.dropdown_items_inner {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  display: none;
  transform: translateY(22px);
  z-index: 1;
  min-width: 120px;
}
.dropdown_items_inner ul {
  background-color: var(--theme_white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--theme_ligth_white);
  padding: 10px;
}
.dropdown_items_inner ul li {
  font-size: 14px;
}

.cart_elements a {
  font-weight: 500;
}

.search_items_inner {
  position: relative;
}
.search_items_inner input {
  padding-right: 45px;
  border-radius: 40px;
}

.searchGlass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 50px;
  cursor: pointer;
}
.searchGlass i {
  color: var(--theme_blue) ed;
}

.top_title {
  font-weight: 500;
  font-size: var(--font_60);
}

.main_title {
  font-size: var(--font_100);
  font-weight: 700;
  margin-bottom: 27px;
}

.sub_title {
  font-size: var(--font_32);
  font-weight: 500;
  line-height: 48px;
}

.content_headerInner {
  max-width: 755px;
  width: 100%;
}

.btn_trial {
  font-size: var(--font_24);
  max-width: 314px;
  width: 100%;
  padding: 12px;
}

.set_bgContent {
  display: flex;
  align-items: center;
  height: 650px;
  position: relative;
  margin: 60px auto 60px;
  z-index: 1;
}

.bg_homeImg {
  position: absolute;
  z-index: -1;
  right: 0;
}
.bg_homeImg img {
  width: 100%;
  height: 100%;
}

.cover_sec {
  padding: 140px 0;
  width: 100%;
  background-position: center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  position: relative;
}
.cover_sec::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 5%);
  height: 100%;
}

.center_coverTitle {
  text-align: center;
  z-index: 1;
  position: relative;
  max-width: 897px;
  margin: 0 auto;
}
.center_coverTitle p {
  color: var(--theme_white);
  font-weight: 500;
  font-size: var(--font_40);
  line-height: 60px;
}

.find_data h3 {
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 16px;
}

.search_section {
  padding: 60px 0;
}

.form_elements {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
}

.form_fields {
  flex: 1 1 280px;
}
.form_fields select,
.form_fields input {
  border: 1px solid var(--theme_ligth_white);
  color: #5e5e5e;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  -webkit-appearance: none;
  appearance: none;
  padding: 8px;
}

/* .icon_drop::after {
  position: absolute;
  content: "";
  width: 16px;
  height: 10px;
  background: url(../images/drop-Icon.png) no-repeat;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-size: contain;
} */

.btn_find {
  max-width: 223px;
  width: 100%;
}

.copright_note {
  color: #0a142f;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
}

.footer_elements {
  background-color: var(--theme_white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
}
.footer_elements ul {
  grid-gap: 40px;
  gap: 40px;
  flex-wrap: wrap;
}
.footer_elements ul li a {
  color: var(--theme_blue);
  font-weight: 500;
}

.locationSet i {
  color: #ff002e;
  font-size: 24px;
}

.titel_line p {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.dotBlock {
  width: 8px;
  height: 8px;
  display: block;
  background: #d9d9d9;
  border-radius: 50%;
}

.deskDettails {
  position: relative;
  max-width: 100%;
}
.deskDettails img {
  max-width: 342px;
  width: 100%;
  z-index: 2;
  position: relative;
  max-height: 200px;
  object-fit: cover;
  height: 100%;
  min-height: 200px;
}
.deskDettails:hover .img_deskDetails {
  opacity: 1;
}
.deskDettails::after {
  background: #225d665c;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 5px;
  bottom: -5px;
  z-index: 1;
}

.img_deskDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.3s;
  z-index: 3;
}

.icon_plus i {
  color: var(--theme_dark_yellow);
  font-size: 30px;
}

.img_deskDetails p {
  color: var(--theme_white);
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  margin-bottom: 16px;
}

.titel_line {
  margin: 24px 0;
}

.item_searchvalue {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  flex-wrap: wrap;
}

.item_flexInner {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 24px);
}

.item_flexInner_card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(33.33% - 16px);
}

.loadMore_action {
  background: var(--theme_dark_yellow);
  font-size: 20px;
  font-weight: 500;
  padding: 9px 23px;
  border-radius: 8px;
  min-width: 250px;
  text-align: center;
}

.loadMore {
  display: flex;
  justify-content: center;
  min-height: 100px;
  align-items: self-end;
  position: relative;
}

/* .loadMore:after {
  content: "";
  background: linear-gradient(2deg, rgb(255, 254, 248) 0%, rgba(255, 255, 255, 0.3) 100%);
  margin-top: -69px;
  filter: blur(5px);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
} */

.desk_list__img img {
  height: 268px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 16px 16px 0 0;
  transition: all 0.3s;
}

.desk_list__img {
  overflow: hidden;
  position: relative;
  border-radius: 16px 16px 0 0;
}

.artical_List {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 24px);
  position: relative;
}
.artical_List:hover .desk_list__img img {
  transform: scale(1.1);
  transition: all 0.3s;
}
.artical_List:hover .set_cardIcon {
  /* transform: rotate(45deg); */
  transition: all 0.3s;
  opacity: 1;
}

.dest_innerDet {
  grid-gap: 24px;
  gap: 24px;
  flex-wrap: wrap;
}
.set_cardIcon{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.set_cardIcon a {
  background: #000000a8;
  width: 35px;
  display: block;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Destinations_sec {
  background: #111111;
  padding: 40px 0;
}

.desk_list_subline {
  background-color: var(--theme_white);
  border-radius: 0 0 16px 16px;
  padding: 16px;
}

.set_50 {
  flex: 0 0 50%;
}

.card_details {
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 12px;
  color: #5e5e5e;
  grid-gap: 2px;
  gap: 2px;
}

.fab_color {
  color: var(--theme_dark_yellow);
  fill: var(--theme_dark_yellow);
}

.desk_list_subline h5 {
  font-size: 20px;
  font-weight: 500;
}

.sub_disc {
  color: #5e5e5e;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  height: 62px;
}

.author_name {
  width: 40%;
}

.set_cardIcon {
  position: absolute;
  right: 12px;
  top: 12px;
  transition: all 0.3s;

}
.artical_List .set_cardIcon{
  opacity: 0;
}
.set_cardIcon i {
  color: var(--theme_white);
}

.dest_inner h3 {
  color: var(--theme_dark_yellow);
  margin: 0px 0 40px;
}

.cart_elements a {
  color: var(--theme_blue);
}

.upload_elements {
  padding: 20px;
}

.dropupload h4 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
}

.dropupload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f8ff;
  border: 1px dashed rgba(56, 78, 183, 0.3019607843);
  border-radius: 4px;
  margin-top: 25px;
  padding: 50px;
}

.img_uploadItem {
  border-radius: 8px;
}

.btn_trash {
  color: #e41d1d;
}
.btn_trash svg {
  fill: #e41d1d;
}

.btn_uploadData {
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}

.upload_imgList ul {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.upload_imgList ul li {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 20px);
}

.disabled_set {
  opacity: 0.5;
  cursor: not-allowed;
}

.field_drops {
  flex-direction: column;
}

.upload_image {
  margin: 20px auto 10px;
}

.set_fulls textarea {
  background: var(--theme_ligth_white);
}

.gallery_aut p {
  margin-bottom: 0;
  font-size: 17px;
  text-align: start;
  font-weight: 400;
}

.sub_title_aut {
  text-decoration: underline;
  font-style: italic;
  color: var(--theme_dark_yellow);
}

.gallery_btns {
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.img_deskInner {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.set_gallery_bg {
  background: url(https://www.findmysurfpic.com/static/media/gallery-bg.753b1a6c.svg) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.card_checkout {
  background-color: var(--theme_white);
  padding: 16px;
  border-radius: 16px;
  border: 6px solid #f7f7f7;
  min-height: 600px;box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.checkOut_list {
  margin-bottom: 24px;
}

.checkout_name {
  color: #5e5e5e;
}

.addInformation {
  margin-top: 24px;
  padding: 24px 0 0;
  border-top: 1px solid #e0e0e0;
}

.colUnder {
  text-decoration: underline;
}

.addInformation p ,.addInformation span{
  color: #5e5e5e;
  font-size: 15px;
}

.addInformation h6 {
  margin-bottom: 16px;
}
.show-more-less-clickable {
  color: #f73388 !important;
  cursor: pointer;
}
.set_fullValue {
  max-width: 100%;
  padding: 10px;
  font-weight: 500;
  font-size: 24px;
}

.checkOut_Layer {
  margin-bottom: 60px;
}

.image_slider {
  max-width: 100%;
  border-radius: 16px;
  width: 100%;
  min-height: 600px;
}

.titel_slider {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--theme_black);
  font-size: 24px;
}

.swiper-button-prev , .swiper-button-next{
  background-color: var(--theme_dark_yellow);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  opacity: 1 !important;
}

.swiper-button-disabled {
  background-color: #dadada;
  border: 1px solid var(--theme_black);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 0px);
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 18px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 0px);
}

.sub_details h4 {
  font-weight: 600;
  margin-bottom: 32px;
}
.sub_details p {
  margin-bottom: 32px;
}

.image_surf img {
  max-width: 590px;
  width: 100%;
  border-radius: 30px;
  height: 100%;
  object-fit: cover;
}
.image_surf{
  height: 100%;
}
.swiper-pagination-bullet {
  background: #000000;
}
.swiper-pagination-bullet-active {
  background: #ffc700;
}

.sec_waves {
  background-color: var(--theme_blue);
  padding: 120px 0;
  position: relative;
}

.waves_title {
  color: var(--theme_white);
  font-size: 40px;
  font-weight: 600;
  line-height: 72px;
}

.waves_bgImg {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  bottom: 0;
  padding: 0;
}

.waves_bgImg img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.profile_card {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  border: 16px solid #f7f7f7;
  padding: 20px;
  background-color: var(--theme_white);
}

.pt_60 {
  padding-top: 60px;
}

.pb_60 {
  padding-bottom: 60px;
}

.prof_from {
  flex-wrap: wrap;
  grid-gap: 7px;
  gap: 7px;
}
.prof_from .form_fields_prof {
  margin-bottom: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 10px);
}

.profile_uploadField {
  flex-direction: column;
}

.w_50Set {
  width: 50% !important;
}

.profile_uploadField {
  grid-gap: 7px;
  gap: 7px;
  width: 100%;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: -9px;
  z-index: 1;
  top: -9px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffd119;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 170px;
  height: 170px;
  position: relative;
  border-radius: 16px;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar_elements {
  align-items: center;
  justify-content: space-between;
  grid-gap: 50px;
  gap: 50px;
  width: 100%;
}

.form_profile_icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

td {
  vertical-align: middle;
}

table.dataTable > tbody > tr {
  border: none;
}

div.dt-container.dt-empty-footer tbody > tr:last-child > * {
  border: none !important;
}

table.dataTable > thead > tr > th,
table.dataTable > thead > tr > td {
  border: none !important;
}

.form_profile_outer {
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.userName {
  color: #404d61;
  font-size: 16px;
}

.dt-column-title {
  color: #888891;
  font-weight: 600;
  font-size: 14px;
}

div.dt-container select.dt-input {
  padding: 4px;
  border-radius: 4px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #f8f8f8 !important;
}

.dt-search label {
  display: none;
}

.dt-length {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.dt-length label {
  margin-bottom: 0;
}

.dt-length label {
  margin-bottom: 0;
  color: #45454a;
}

.table_userDetails {
  margin-top: 37px;
}
.table_userDetails .dt-container .dt-paging .dt-paging-button.current {
  background-color: var(--theme_blue) !important;
  color: var(--theme_white) !important;
  border-radius: 4px;
}
.table_userDetails .dt-container .dt-paging .dt-paging-button.current:hover {
  color: var(--theme_white) !important;
}
.table_userDetails div.dt-container select.dt-input {
  border-radius: 5px !important;
  border-color: #dfdfdf !important;
}

.dt-info {
  font-size: 14px;
}

div.dt-container .dt-paging .dt-paging-button.disabled,
div.dt-container .dt-paging .dt-paging-button.disabled:hover,
div.dt-container .dt-paging .dt-paging-button.disabled:active {
  font-size: 30px;
  padding: 0;
}

.paging_full_numbers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dt-search input {
  border-radius: 5px !important;
  border-color: #dfdfdf !important;
  position: relative;
  padding-left: 40px !important;
  color: #45454a !important;
}

.dt-search {
  max-width: 300px;
  display: flex;
  margin-left: auto !important;
  position: relative;
}
.dt-search::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: url(https://www.findmysurfpic.com/static/media/searchIcon.fb514aec.svg);
  content: "";
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  left: 16px;
}

.edit_propsTable {
  grid-gap: 15px;
  gap: 15px;
}
.edit_propsTable i {
  color: #5e5e5e;
}
.edit_propsTable svg {
  /* fill: #5e5e5e; */
}

.form_gallery_data .form_fields select {
  border-color: #5e5e5e;
}

.img_deskInner_card {
  position: relative;
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1;
}

.img_deskInner_card p {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
}

.card_images_admin {
  border-radius: 16px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.deskDettails_card {
  position: relative;
  height: 290px;
}
.deskDettails_card::after {
  width: 100%;
  height: 200px;
  content: "";
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  bottom: 0;
  left: 0;
  position: absolute;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.btn_elements_gallery {
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}

.btn_edit {
  background-color: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.btn_edit svg {
  /* fill: #000; */
}

.trash_btn {
  color: #d6093f;
}
.trash_btn svg {
  color: #d6093f;
}

.btn_onhover {
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.title_valueEye {
  font-size: 16px;
  font-weight: 500;
}

.title_valueEye,
.icon_outer_drop {
  display: none;
}

.eye_btn:hover .btn_onhover {
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  left: -40px;
  position: relative;
  background: #fff;
  height: 40px;
  border-radius: 100px;
  padding: 0px 10px;
}
.eye_btn:hover .title_valueEye,
.eye_btn:hover .icon_outer_drop {
  display: block;
}

.mt_44 {
  margin-top: 44px;
}

.onEditor {
  max-width: 940px;
  width: 100%;
}
.onEditor p {
  line-height: 32px;
  color: #5e5e5e;
}

.shopping_btn {
  background-color: #ffd119;
}

.oneditor_outer {
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.text_areaItem {
  min-height: 90px !important;
  height: 100%;
  border: 1px solid #e0e0e0;
  color: #5e5e5e;
  line-height: 32px;
  font-size: 15px;
}

.gallery_innerTitle {
  color: #5e5e5e;
  font-size: 24px;
  line-height: normal !important;
  margin-bottom: 0 !important;
}

.desk_card_outer {
  position: relative;
}
.desk_card_outer .card_images_admin {
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  height: 180px;
}

.img_desk_card {
  background: #fff;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.btn_edit_block {
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.public_items {
  align-items: center;
  justify-content: space-between;
}

.prof_from .form_fields_prof {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 4px);
}

.btn_edit_icons {
  border: 1px solid #e0e0e0;
  background: #fff;
  display: block;
  padding: 3px 12px;
  border-radius: 100px;
}

.public_items {
  /* margin-top: 10px; */
}

.desk_cardHoverItems {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.desk_cardHoverItems .questioLIne {
  color: #fff;
  text-align: center;
  font-weight: 500;
}

.goBackbtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: #ffd119;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.item_flexInner:hover .desk_cardHoverItems {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.action_cal_del {
  justify-content: space-between;
  margin-top: 28px;
}
.action_cal_del button {
  color: #fff;
  border: 1px solid #fff;
  padding: 6px 18px;
  border-radius: 4px;
}

.btn_del {
  border-color: #f60000 !important;
  background-color: #f60000 !important;
}

.contact_subTitle {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}

.sec_contactUs {
  padding: 130px 0;
}

.form_contact {
  max-width: 476px;
  width: 100%;
}

.form_contact textarea {
  height: 80px;
}

.custom_support {
  color: #110163;
}

.form_calllist {
  margin-top: 20px;
}
.form_calllist ul {
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.form_calllist ul a {
  color: #110163;
  font-weight: 500;
  font-size: 18px;
}
.form_calllist ul a span {
  color: #ffd119;
  margin-right: 8px;
}

.mobile-nav {
  display: none;
} 
.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  background-color: #110163 !important;
}
.header__sticky-sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: var(--theme_white);
  box-shadow: 0px 4px 15px 0px rgba(129, 127, 127, 0.1019607843);
  animation: slideDown 0.35s ease-out;
  .header_bar {
      padding: 22px 0 22px;
  }
}
@keyframes slideDown {
  from {
      transform: translateY(-100%);
  }
  to {
      transform: translateY(0);
  }
}
@media screen and (max-width: 1399px) {
  :root {
    --font_40: 2.5rem;
    --font_60: 2.75rem;
    --font_75: 3.3875rem;
    --font_100: 5.25rem;
    --font_32: 1.75rem;
  }
  .set_bgContent {
    margin: 30px auto 30px;
  }
  h3 {
    font-size: 36px;
  }
  .card_details {
    font-size: 11px;
  }
  .desk_list_subline h5 {
    font-size: 18px;
  }
  .logo_elements {
    grid-gap: 24px;
    gap: 24px;
  }
  .logo_elements {
    width: 40%;
  }
  .menu_layer_menu {
    width: 60%;
  }
}
@media screen and (max-width: 1199px) {
  .form_elements {
    flex-wrap: wrap;
  }
  .item_flexInner,
  .artical_List {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 16px);
  }
  .waves_title {
    font-size: 38px;
    line-height: 64px;
  }
  :root {
    --font_40: 28.8px;
    --font_60: 32px;
    --font_75: 38.2px;
    --font_100: 68px;
    --font_32: 24px;
    --font_24: 22.4px;
  }
  .sec_contactUs {
    padding: 40px 0;
  }
  .contact_subTitle,
  .waves_title {
    font-size: 30px;
  }
  .form_calllist ul a {
    font-size: 16px;
  }
  .header_elements .menu_items ul {
    grid-gap: 15px;
    gap: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .menu_layer .search_items{
    display: none !important;
  }
  .row.set_howWork {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.row.set_howWork .col-md-6 {
  flex: 0 0 auto;
  width: 100%;
}
.image_surf img {
  max-width: 100%;
}
  .menu_layer_menu {
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;

    .header_elements .menu_layer {
      grid-gap: 15px;
      gap: 15px;
    }
    .list_dropMenu,
    .header_elements .menu_items ul li a,
    .cart_elements a {
      font-size: 14px;
    }
  }
  .logo_text img {
    min-width: 130px;
  }
  .logo_elements {
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
  }
}
@media screen and (max-width: 991px) {
  .instaLogo{
    display: block !important;
  }
  .header_elements .menu_layer {
    align-items: center !important;
    flex-direction: row !important;
  }
  .form_elements {
    flex-wrap: wrap;
    justify-content: center;
  }
  .item_flexInner,
  .artical_List {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 24px);
  }
  .bg_homeImg {
    height: 100%;
  }
  .bg_homeImg img {
    object-fit: cover;
  }
  .menu_items {
    position: absolute;
    top: 95px;
    background: #fff;
    padding: 10px;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .logo_main
  {
    margin-bottom: 0;
  }
  .list_dropMenu{
    width: 100%;
    justify-content: space-between;
  }
  .header_elements .menu_items ul {
    width: 100%;
    flex-direction: column;
  }
  .btn_down {
    position: absolute;
    right: 0;
    top: 0;
  }
  .dropdown_elements {
    align-items: self-start;
    flex-direction: column;
  }
  .dropdown_items_inner {
    position: relative;
    width: 100%;
    transform: translateY(0px);
    z-index: 1;
    width: 100%;
    right: 0;
    transition: all 0.3s;
  }
  .dropdown_items {
    width: 100%;
  }
  .logo_elements {
    grid-gap: 20px;
    gap: 20px;
    width: 90%;
  }
  .item_flexInner_card {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 12px);
  }
  .deskDettails_card {
    height: 260px;
  }
  .logo_text img {
    min-width: 100px;
  }
  .menu_layer_menu {
    grid-gap: 5px;
    gap: 5px;
    width: 25%;
    margin: 0;
  }
  .mobile-nav {
    right: 15px;
    margin-top: 10px;
    top: 12px;
    cursor: pointer;
    z-index: 2;
    display: block;
    /* margin-left: 20px; */
  }
  .search_items {
    width: 138%;
    position: relative;
    margin-top: 5px;
  }
  .bar1,
  .bar2,
  .bar3 {
    background-color: var(--theme_black);
    cursor: pointer;
    height: 2px;
    margin: 7px 0px;
    transition: all 0.4s ease 0s;
    width: 30px;
  }
  .bar2 {
    width: 20px;
    left: -5px;
    position: relative;
  }
  .header_elements .header_bar {
    align-items: center;
  }
  .menu_items_release {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  .dropdown_elements .dropdown {
    width: 100% !important;
    border-bottom: 1px solid #cccccc4d;
    padding-bottom: 5px !important;
  }
  .dropdown_elements .dropdown-menu.show {
    display: block;
    width: 100%;
  }
  .header_elements .menu_items ul li a {
    font-size: 14px;
    width: 100%;
    display: block;
    border-bottom: 1px solid #cccccc4d;
    padding-bottom: 5px;
  }
  .header_elements .menu_items ul li:last-child a {
    border-bottom: none;
  }
}
@media screen and (max-width: 767px) {
  .image_surf img{
    object-position: 0px;
  }
  .break_word{
    display: block;
    word-break: break-all;
  }
  .logo_text img {
    max-width: 180px;
}
  .pb_50 {
    padding-bottom: 25px;
}
  .sub_details {
    margin-top: 20px;
    padding: 0 15px 15px;
}
  .item_flexInner_card {
    flex-basis: 100%;
  }
  .logText {
    display: none;
  }
  .header_elements .header_bar {
    align-items: flex-start;
  }
  .logo_elements {
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .card_checkout {
    margin-top: 20px;
  }
  .search_section {
    padding: 30px 0;
  }
  .find_data h3 {
    line-height: 42px;
    margin-bottom: 16px;
  }
  .header_elements .menu_layer {
    align-items: baseline;
  }
  .titel_line p {
    font-size: 16px;
  }
  h3 {
    font-size: 28px;
  }
  .item_flexInner,
  .artical_List,
  .prof_from .form_fields_prof {
    flex: 100% 1;
  }
  .w_50Set {
    width: 100% !important;
  }
  .btn {
    padding: 10px 20px;
  }
  .set_fullValue {
    font-size: 18px;
  }
  .titel_slider {
    font-size: 20px;
  }
  .profile_uploadField,
  .avatar_elements {
    flex-direction: column;
  }
  .avatar_elements {
    grid-gap: 10px;
    gap: 10px;
  }
  .avatar-upload .avatar-preview {
    width: 200px;
    height: 200px;
  }
  .upload_imgList ul li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
  }
  h4 {
    font-size: 26px;
  }
  .footer_elements {
    padding: 10px;
  }
  .deskDettails img {
    max-width: 100%;
  }
  .set_bgContent {
    height: 400px;
    margin: 30px auto 30px;
  }
  :root {
    --font_60: 24px;
    --font_100: 50px;
    --font_32: 18px;
    --font_24: 18.4px;
  }
  .sub_title {
    line-height: 34px;
  }
  .center_coverTitle p {
    line-height: 46px;
  }
  .cover_sec {
    padding: 100px 0;
  }

  .pb_60 {
    padding-bottom: 30px;
  }
  .pb_60 {
    padding-top: 30px;
  }
  .image_galleryTiitle {
    font-size: 24px;
  }
  .mb_40 {
    margin-bottom: 20px;
  }
  .gallery_innerTitle {
    font-size: 16px;
  }
  .goBackbtn {
    margin-bottom: 10px;
  }
  .searchHome_btn {
    width: 100%;
  }
  div#myTable_wrapper > .dt-layout-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dt-search {
    max-width: 180px;
  }
  div.dt-container .dt-paging .dt-paging-button {
    padding: 0.3em 0.8em;
  }
  .image_slider {
    min-height: 440px;
}
.form_calllist ul {
 
  flex-direction: column;
}
.custom_support {
  text-align: center;
}
.waves_bgImg {
  width: 50%;
  opacity: 0.65;
}
.contact_subTitle, .waves_title {
  z-index: 1;
  position: relative;
}

.prof_title{
  width: 100% !important;
  margin-bottom: 10px;
}
.profile_card_payout{
  width: 100% !important;
  grid-gap: 0 !important;
  gap: 0 !important;
  flex-direction: column;
}
.alert_update a button.btn.btn-purple.btn-sm.text-white {
  height: 40px;
}
.center_password {
  padding: 30px 20px;
}
.waves_title {
  line-height: 40px;
}
.btn {
  padding: 7px 20px;
}
}
@media screen and (max-width: 575px) {
  .titel_line p {
    flex-wrap: wrap;
  }
  .search_items_inner input {
    font-size: 12px;
  }
  .footer_elements ul {
    grid-gap: 10px;
    gap: 10px;
    font-size: 14px;
  }
  .footer_elements {
    padding-top: 20px;
  }
  div#myTable_wrapper > .dt-layout-row {
    display: block;
  }
  .dt-search {
    max-width: 100%;
  }
  .paging_full_numbers {
    justify-content: center;
  }
  .center_login {
    padding: 40px 20px 0;
}
.set_center {
  min-height: auto;
  padding: 50px 10px;
}
}
@media screen and (max-width: 575px) {
  .search_items {
    width: 145%;
  }
}



