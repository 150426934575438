.form_icon {
  top: 46% !important;
}

.form_fields {
  margin-bottom: 20px !important;
}

.form_fields_drops select,
.form_fields_drops input {
  border: 1px solid #e7e7e7;
  color: #5e5e5e;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px;
}

.cret_details {
  a {
    color: #4668ff !important;
  }
}
.requiredInput {
  position: absolute;
  left: 5px;
  bottom: -20px;
  font-size: 12px;
  color: red;
}

.checkboxReq {
  border-color: red;
}

.google-map {
  width: 100%;
}

.search_section fieldset {
  margin-bottom: 0 !important;
}

.cart_icon {
  margin-right: 5px !important;
}

.form_fields_drops {
  position: relative !important;
}

.form_fields_drops .select {
  border: 1px solid #e7e7e7;
  color: #5e5e5e;
  border-radius: 8px;
  width: 100%;
  /* height: 48px; */
  appearance: none;
  /* padding: 8px; */
}

.prof_from .form_fields_prof {
  position: relative !important;
}

.center_password {
  background-color: var(--theme_white);
  max-width: 550px;
  width: 100%;
  border-radius: 10px;
  border: 10px #f7f7f7 solid;
  padding: 69px 96px;
  z-index: 99999;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0px;
  background-color: rgb(145 145 145 / 84%) !important;
  z-index: 99999;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Ensure the overlay is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-select {
  position: relative;
}
.list_dropMenu {
  background: unset;
  border: unset;
  padding: unset !important;
  color: #110163;
  font-weight: 500;
  font-size: 16px;
}
.menu_layer_menu .list_dropMenu:hover,
.menu_layer_menu .list_dropMenu:focus-visible,
.menu_layer_menu
  .list_dropMenu:first-child:active
  .menu_layer_menu
  .list_dropMenu.show {
  background-color: #fff !important;
  color: #110163 !important;
}
.menu_layer_menu .btn.show,
.menu_layer_menu .btn:first-child:active {
  background-color: #fff !important;
  color: #110163 !important;
}

.onEditor {
  align-items: center;
  gap: inherit;
}

.prof_title {
  font-family: "fantasy";
}

.input-group {
  flex-wrap: nowrap;
}
.input-group-text {
  height: 44px;
}
/* .dt-layout-row{
    display: flex !important; 
    justify-content: space-between !important;
    align-items: center !important;
} */

table.dataTable th.dt-type-numeric {
  text-align: left !important;
}

table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
  text-align: left;
}
.btn-purple {
  background-color: #110163 !important;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.text_small {
  color: #ffaaaa;
  font-family: serif;
}

.item_flexInner .icon_plus {
  display: flex;
  gap: 10px;
}
main {
  min-height: calc(100vh - 190px);
}
.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}
.css-1fdsijx-ValueContainer {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 8px;
}
.css-13cymwt-control,
.css-1fdsijx-ValueContainer,
.css-1hb7zxy-IndicatorsContainer {
  background: #f1f1f1 !important;
  border: none !important;
}
.css-1dimb5e-singleValue {
  font-size: 14px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  border-radius: 10px !important;
}

.css-13cymwt-control {
  border-radius: 10px !important;
}
.searchHome_btn {
  height: 50px;
  gap: 5px;
}
.set_end {
  align-items: flex-end;
  z-index: 9;
  position: relative;
}
.no_record {
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.css-t3ipsp-control {
  border: none !important;
  box-shadow: none !important;
  background: #f1f1f1 !important;
}
.css-t3ipsp-control:hover {
  border: none !important;
  box-shadow: none !important;
  background: #f1f1f1 !important;
}
.dropupload {
  position: relative;
}
.dropupload input{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}
.counter_dropBox{
    font-weight: 500;
}
div.dt-container .dt-search input ,.table_userDetails div.dt-container select.dt-input{
    height: 40px;
}
.avatar-upload .avatar-edit input + label {

    display: flex !important;
    justify-content: center;
    align-items: center;
}

.img_desk_card {
 background: unset !important;
    position: absolute;
    z-index: 1;
    border: none !important;
    bottom: 0;
    right: 0;
}

.public_trash {
    gap: 10px;
}
.img_uploadItem {
    height: 160px;
    width: 100%;
    object-fit: cover;
}
.uploadInner{
    position: relative;
}
.btn_uploadData.d-flex {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 1;
}
.contact_info{
  position: relative;
}
.sec_sliderGallery{
  padding-top: 30px;
}

.image_slider {

  max-height: 570px;
  height: 100%;
  object-fit: cover;
}
.payment_logo {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}
.pay_logo {
  flex: 0 0 calc(25% - 6px);
}
.btn_payment {
  a {
      min-width: auto;
  }

}
.btn_border {
  background-color: #fff !important;
  color: #ffd119 !important;
  border: 1px solid #ffd119 !important;
}
.css-19bb58m {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px !important;
}
.css-19bb58m input {
  height: 45px;
}
.del_usertitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
  color: red;
}

.del_user .card_images_admin {
  opacity: .25;
}
.alert_update{
  gap: 15px;
}
.alert_update a button.btn.btn-purple.btn-sm.text-white {
  min-width: 170px;
  height: 50px;
}
.alert_custom{
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
}
.css-hlgwow {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.dropdown-menu {
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 15%);
  position: relative;
  border-radius: 8px;
  inset: inherit !important;
  transform: none !important;
}
.css-1jqq78o-placeholder {
  font-size: 14px;
}
.multiple{
  position: absolute;
  z-index: 9;
  bottom: 15px;
  right: 15px;
}
.small-text{
  font-size: 22px;
  color: #525252;
}
.instaLogo{
  display: none;
}
.instaLogo img{
  max-width: 30px !important;
}